<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>View Results</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Candidate Exam Results
                </div>
              </div>
            </div>
            <div class="breadcrumb d-flex justify-space-between">
                <div>
                    <div class="title"> <strong>Enroller</strong>:  {{candidate.owner ? candidate.owner.full_name : ''}} </div>
                    <div class="title"> <strong>Phone</strong>:  {{candidate.owner ? candidate.owner.phone : '' }} {{candidate.owner ? candidate.owner.mobile : '' }} </div>
                </div>
                <div class="text-left ml-2">
                    <div class="title"> <strong>Email</strong>:  {{candidate.owner ? candidate.owner.email : ''}} </div>
                    <div class="title"> <strong>State</strong>:  {{candidate.owner ? candidate.owner.score_name : ''}} </div>
                </div>
            </div>
          </div>
          <div class="card-body">
              <v-skeleton-loader
                type="table-thead"
                v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                v-if="loading"
                type="table-row-divider@5"
              >
              </v-skeleton-loader>

              <table v-if="!loading" class="table  table-vertical-center"
                    id="kt_advance_table_widget_4">
                <thead>
                    <tr class="text-left">
                        <th class="px-3">Attempt ID</th>
                        <th class="px-3">Enrolment Key</th>
                        <th class="px-3">Result Type</th>
                        <th class="px-3">Submitted Date</th>
                        <th class="px-3">Status</th>
                        <th class="px-3">Result</th>
                        <th class="pr-3 text-center">Options</th>
                    </tr>
                </thead>
                <tbody>
                    <template>
                        <tr v-if="examResults.length != 0" v-for="(result, index) in examResults" :key="index">
                            <td class="px-2">
                                <span  class=""
                                >
                                    {{result.attempt_id}}
                                </span>
                            </td>
                            <td class="px-2">
                                <span  class="font-weight-bolder d-block font-size-lg"
                                >
                                    {{result.candidate.exam_key}}
                                </span>
                            </td>
                            <td class="px-2">
                                <span  class="font-weight-bolder d-block font-size-lg"
                                >
                                    {{result.is_official ? 'Official' : 'Practice' }}
                                </span>
                            </td>
                            <td class="px-2">
                                <span  class="font-weight-bolder d-block font-size-lg"
                                >
                                    {{result.formatted_completion_time ? result.formatted_completion_time : '-'}}
                                </span>
                            </td>
                            <td class="px-2">
                                <span  class="font-weight-bolder d-block font-size-lg"
                                >
                                    {{result.exam_status_text}}
                                </span>
                            </td>
                            <td class="px-2">
                                <span v-if="result.is_marked && result.exam_status == 'completed'" class="badge badge-success"
                                >
                                    Available
                                </span>
                                <span class="badge badge-warning" v-else>
                                    Pending
                                </span>
                            </td>
                            <td class="pr-0 text-center">
                                <template>
                                    <b-dropdown
                                        size="sm"
                                        variant="link"
                                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                        no-caret
                                        right
                                        no-flip
                                    >
                                        <template v-slot:button-content>
                                        <i class="ki ki-bold-more-hor"></i>
                                        </template>
                                        <!--begin::Navigation-->
                                        <div v-if="! result.is_official" class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a  class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-edit</v-icon>
                                                </span>
                                                <span class="navi-text">
                                                    Mark as Complete
                                                </span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>

                                        <div v-if="result.is_official" class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a  class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-exclamation-triangle</v-icon>
                                                </span>
                                                <span class="navi-text">
                                                    Grant Second Official Exam Attempt
                                                </span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" @click="viewMarksObtained(result)" class="navi-item">
                                                <a  class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-chart-line</v-icon>
                                                </span>
                                                <span class="navi-text">
                                                    View Marks Obtained
                                                </span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" @click="viewFeedbackReport(result.id)" class="navi-item">
                                                <a  class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-file</v-icon>
                                                </span>
                                                <span class="navi-text">
                                                    View Feedback Report
                                                </span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text @click="printDigitalCertificate(result.id)" tag="div" class="navi-item">
                                                <a  class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-print</v-icon>
                                                </span>
                                                <span class="navi-text">
                                                    Print Digital Certificates
                                                </span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text @click="viewSupervisorInormation(result.id)" tag="div" class="navi-item">
                                                <a  class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-user</v-icon>
                                                </span>
                                                <span class="navi-text">
                                                    Supervisor Information
                                                </span>
                                                </a>
                                            </b-dropdown-text>

                                            <b-dropdown-text tag="div" class="navi-item">
                                                <a  class="navi-link">
                                                <span class="navi-icon">
                                                    <v-icon color="darken-2">fas fa-sync</v-icon>
                                                </span>
                                                <span class="navi-text">
                                                    Resync Data with RS
                                                </span>
                                                </a>
                                            </b-dropdown-text>
                                        </div>
                                    </b-dropdown>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="examResults.length == 0">
                            <td colspan="7" class="text-center">
                                No results found
                            </td>
                        </tr>
                    </template>
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </div>

    <!-- marks obtained modal box -->
    <v-dialog
        v-model="showMarksObtainedDialog"
        max-width="800"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span> Marks Obtained </span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    Total Mark
                                </strong>
                            </div>
                            <div class="title">
                                {{availableMarks}}
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    Obtained
                                </strong>
                            </div>
                            <div class="title">
                                {{mark}}
                            </div>
                            <div class="body">
                                {{remarks}}
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="btn btn-error"
                    dark
                    medium
                    @click="closeDialog"
                >
                Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- marks obtained modal box ends -->

        <!-- marks obtained modal box -->
    <v-dialog
        v-model="showSupervisorDialog"
        max-width="800"
        scrollable
    >
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span> Supervisor Details </span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row v-if="examSupervisor">
                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    Name
                                </strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    {{examSupervisor.full_name}}
                                </strong>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    Authorisation Time
                                </strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    {{examSupervisor.created_at}}
                                </strong>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    Type
                                </strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    {{examSupervisor.authorisation_type}}
                                </strong>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    Phone
                                </strong>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="title">
                                <strong>
                                    {{examSupervisor.phone}}
                                </strong>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <div class="ml-2 title">
                            No supervisor added
                        </div>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="btn btn-error"
                    dark
                    medium
                    @click="closeDialog"
                >
                Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- marks obtained modal box ends -->

  </v-app>
</template>

<script>
import CandidateService from "@/services/candidate/CandidateService";
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import CandidateOnlineExamSupervisorService from "@/services/candidate/online-exam-supervisor/CandidateOnlineExamSupervisorService";

const candidate = new CandidateService();
const candidateOnlineExamResult = new CandidateOnlineExamResultService();
const candidateOnlineExamSupervisor = new CandidateOnlineExamSupervisorService();

export default {
    data(){
        return {
            examKey: '',
            candidate_id: '',
            examResults: [],
            loading: true,
            showMarksObtainedDialog: false,
            showSupervisorDialog: false,
            mark: null,
            availableMarks: null,
            remarks: '',
            candidate: '',
            examSupervisor: ''
        }
    },
    methods:{
        getExamKey(){
            this.examKey = this.$route.params.examKey;
            this.getCandidateInfo();
        },
        getCandidateInfo(){
            this.loading = true;
            candidate
            .getCandidateSummary(this.examKey)
            .then((response) => {
                this.candidate = response.data.candidate;
                this.candidate_id = response.data.candidate.id;
                this.getCandidateExamResults();
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        getCandidateExamResults(){
            candidateOnlineExamResult
            .getByCandidate(this.candidate_id)
            .then((response) => {
                this.examResults = response.data.examResult;
            })
        },
        viewMarksObtained(result){
            this.showMarksObtainedDialog = true;
            let result_report = JSON.parse(result.result_report);
            this.mark = result_report.mark;
            this.availableMarks = result_report.availableMarks;
            this.remarks = result_report.remarks;
        },
        closeDialog(){
            this.showMarksObtainedDialog = false;
            this.showSupervisorDialog = false;
        },
        viewFeedbackReport(resultId){
            candidateOnlineExamResult
            .getExamResultPdf(resultId);
        },
        printDigitalCertificate(resultId){
            candidateOnlineExamResult
            .getDigitalCertificatePdf(resultId);
        },
        viewSupervisorInormation(resultId){
            this.showSupervisorDialog = true;
            candidateOnlineExamSupervisor
            .getByOnlineExamResultId(resultId)
            .then((response) => {
                if(response.data.status == 'OK'){
                    this.examSupervisor = response.data.candidateOnlineExamSupervisor;
                }else{
                    this.examSupervisor = '';
                }
            })
            .catch((err) => {
                this.examSupervisor = '';
            })
            .finally(() => {
            });
        }
    },
    mounted(){
        this.getExamKey();
    }
};
</script>
